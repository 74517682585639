import React, { memo, useMemo } from 'react';
import { TableCell } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { DateMessage } from 'ui/components/DateTooltip/types';
import { DateTooltip } from 'ui/components/DateTooltip';
import { UserV2 } from 'services/user';
import { TIMEZONES } from 'helpers/timezones';
import { useGetIntlDateFormatString } from 'helpers';
import { useSelector } from 'react-redux';
import { getLocations } from 'services/locations';

const UserRow: React.FC<RenderCustomRowProps<UserV2>> = (props) => {
  const { row, columns } = props;

  const { items: locations } = useSelector(getLocations);

  const intlFormatDate = useGetIntlDateFormatString();

  const itemActive = useMemo(() => columns.length === 2, [columns]);

  const defaultLocation = useMemo(
    () => locations.find((l) => l.id === row.defaultLocationId),
    [locations]
  );

  const tooltipDates: DateMessage[] = useMemo(
    () => [
      { date: row.dateCreated, message: 'Date Created' },
      { date: row.dateLastModified, message: 'Date Last Modified' },
    ],
    [row]
  );

  const dateValue: string = useMemo(() => {
    const dateField = !itemActive ? columns[4].field! : null;
    const date = dateField ? _.get(row, dateField, null) : null;

    return date ? moment(date).format(intlFormatDate) : '';
  }, [columns, row, itemActive, intlFormatDate]);

  const timezone = useMemo(() => {
    if (!row.timezone) {
      return '';
    }

    const timezone = TIMEZONES.find((t) => t.utc.includes(row.timezone!));

    return timezone ? timezone.text : row.timezone;
  }, [row.timezone]);
  return (
    <>
      <TableCell>{_.get(row, 'firstName')}</TableCell>
      <TableCell>{_.get(row, 'lastName')}</TableCell>
      {!itemActive && (
        <>
          <TableCell>{timezone}</TableCell>
          <TableCell>{defaultLocation?.name}</TableCell>
          <DateTooltip values={tooltipDates}>
            <TableCell>{dateValue}</TableCell>
          </DateTooltip>
        </>
      )}
    </>
  );
};

export default memo(UserRow);
